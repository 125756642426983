import { useMemo } from "react";
import { FirebaseTemplatesProps, IMCardsItems } from "src/types/temps";
import axiosInstance, { endpoints, fetcher } from "src/utils/axios";
import { errorProcess } from "src/utils/error-process";
import useSWR from "swr";
import { storage } from 'src/firebase';
import { ref, StorageReference, getDownloadURL, list, listAll } from 'firebase/storage';
import { localStorageGetItem } from "src/utils/storage-available";

type resPostProp = {
    loading: boolean,
    error: any,
    isValidating: boolean
}

const options = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
};

type ResponseReturn<T> = {
    data: T;
    loading: boolean;
    error: any;
    count?: number;
    mutate?: () => void;
}


export function useGetBusinessCard(id: string, templateId?: string): {
    card: IMCardsItems,
    Loading: boolean,
    error: any,
    mutate: () => void
} {

    const URL = templateId ? `${endpoints.dashboard.customer.business.getBusinessCardDuringEdit(id, templateId)}` : `${endpoints.dashboard.customer.business.getBusinessCard(id)}`;

    const { data, isLoading, error, mutate } = useSWR(URL, fetcher, options);

    const memoizedValue = useMemo(
        () => ({
            card: data || {},
            Loading: isLoading,
            error,
            mutate
        }),
        [data, error, isLoading, mutate]
    );

    return memoizedValue;
}

export async function deleteBusinessCard(id: string): Promise<resPostProp> {

    const token = JSON.parse(localStorageGetItem('infos') || '{}')?.refreshToken;

    const URL = `${endpoints.dashboard.customer.business.delete(id)}`;
    try {
        const res = await axiosInstance.delete(URL);
        return {
            loading: false,
            isValidating: !!res.data,
            error: null
        };
    } catch (error: any) {
        const errMsg = errorProcess(error);
        console.log(error);

        return {
            loading: false,
            error: errMsg,
            isValidating: false,
        };
    }
}


export function useGetBusinessCustomerCards(): ResponseReturn<IMCardsItems[]> {
    const URL = `${endpoints.dashboard.admin.businessCard.businessCustomerCards}`;

    const { data, isLoading, error } = useSWR(URL, fetcher, {
        ...options,
        keepPreviousData: true,
    });

    const memoizedValue = useMemo(
        () => ({
            data: data?.businessCards || [] as IMCardsItems[],
            count: data?.totalCount || data?.businessCards?.length || 0,
            loading: isLoading,
            error
        }),
        [data, error, isLoading]
    );

    return memoizedValue;
}

export async function GetAallLogosCards(ids: string[], customerId: string): Promise<{ error: any, logos: FirebaseTemplatesProps }> {
    const memoizedValue: { error: any, logos: FirebaseTemplatesProps } = {
        error: null,
        logos: []
    };
    try {
        for (const id of ids) {

            const imagesRef = ref(storage, `${process.env.REACT_APP_CUSTOMER_FOLDER}/${customerId}/${id}`);
            const response = await listAll(imagesRef);
            const urls = await Promise.all(response.items.map(async (item) => {
                const url = await getDownloadURL(item);
                return { url, name: item.name };
            }));

            const filtredLogos = urls.filter(url => url.name === 'logo').map(url => url.url);
            memoizedValue.logos.push({ id, images: filtredLogos });
        }
        return memoizedValue;
    } catch (error) {
        console.log(error);
        const errMsg = errorProcess(error);
        memoizedValue.error = errMsg;
        return memoizedValue;
    }
}

export async function GetLogoCard(cardId: string, customerId: string): Promise<{ error: any, logo?: string, logoHub?: string, photoProfile?: string }> {
    const memoizedValue: { error: any, logo?: string, logoHub?: string, photoProfile?: string } = {
        error: null
    };
    try {
        const imagesRef = ref(storage, `${process.env.REACT_APP_CUSTOMER_FOLDER}/${customerId}/${cardId}`);
        const response = await list(imagesRef);
        const urls = await Promise.all(response.items.map(async (item) => {
            const url = await getDownloadURL(item);
            return { url, name: item.name };
        }));
        memoizedValue['logo'] = urls.filter(url => url.name === 'logo')[0]?.url;
        memoizedValue['logoHub'] = urls.filter(url => url.name === 'logoHub')[0]?.url;
        memoizedValue['photoProfile'] = urls.filter(url => url.name === 'photoProfile')[0]?.url;
        return memoizedValue;
    } catch (error) {
        console.log(error);
        const errMsg = errorProcess(error);
        memoizedValue.error = errMsg;
        return memoizedValue;
    }
}

export async function GetAvatarUser(customerId: string): Promise<{ error: any, avatar?: string }> {
    const memoizedValue: { error: any, avatar?: string } = {
        error: null
    };
    try {
        const imagesRef = ref(storage, `${process.env.REACT_APP_CUSTOMER_FOLDER}/${customerId}`);
        const response = await list(imagesRef);        
        const urls = await Promise.all(response.items.map(async (item) => {
            const url = await getDownloadURL(item);
            return { url, name: item.name };
        }));
        memoizedValue['avatar'] = urls.filter(url => url.name === 'avatar')[0]?.url;
        return memoizedValue;
    } catch (error) {
        console.log(error);
        const errMsg = errorProcess(error);
        memoizedValue.error = errMsg;
        return memoizedValue;
    }
}




interface LogoInfo {
    name: string;
    url: string;
}
export async function getAllLogoLinks(cardId: string, customerId: string): Promise<{ error: any, logos: LogoInfo[] }> {
    const memoizedValue: { error: any, logos: LogoInfo[] } = {
        error: null,
        logos: []
    };

    try {
        const imagesRef: StorageReference = ref(storage, `${process.env.REACT_APP_CUSTOMER_FOLDER}/${customerId}/${cardId}/links`);
        const response = await list(imagesRef);

        const logoPromises: Promise<LogoInfo>[] = response.items.map(async (item) => {
            const url = await getDownloadURL(item);
            const name = item.name;
            return { name, url };
        });

        const logoInfos: LogoInfo[] = await Promise.all(logoPromises);

        memoizedValue.logos = logoInfos;
    } catch (error) {
        console.error(error);
        const errMsg = errorProcess(error);
        memoizedValue.error = errMsg;
    }

    return memoizedValue;
}


export function useSearchCardName(search: string, id: string) {
    const URL = search ? endpoints.dashboard.customer.business.searchCardName(search, id) : '';
    const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
        keepPreviousData: true,
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    });

    const memoizedValue = useMemo(
        () => ({
            searchResults: !!data,
            searchLoading: isLoading,
            searchError: error,
            searchValidating: isValidating,
        }),
        [data, error, isLoading, isValidating]
    );

    return memoizedValue;
}

