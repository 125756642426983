import { forwardRef } from 'react';
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
// import wsam_logo from 'src/assets/wsam_logo.svg';
// import wsam_logo_named from 'src/assets/wsam_logo_named.svg';


// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
    disabledLink?: boolean;
    variant?: 'small' | 'medium' | 'large';
    isNamed?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
    ({ disabledLink = false, isNamed = false, variant = 'medium', sx, ...other }, ref) => {
        const logoSize = variant === 'large' ? { xs: 40, sm: 42, md: 45, lg: 50 } : variant === 'medium' ? { xs: 30, sm: 37, md: 40, lg: 45 } : { xs: 26, md: 27, lg: 28 };
        const logo = (
            <Box
                ref={ref}
                component="div"
                sx={{
                    width: isNamed ? { xs: 80, sm: 100, md: 120, lg: 170 } : logoSize,
                    height: 'auto',
                    display: 'inline-flex',
                    ...sx,
                }}
                {...other}
            >
                <img
                    src={isNamed ? '/assets/home/wsam_logo_named.svg' : '/assets/home/wsam_logo.svg'}
                    width={'100%'}
                    height={'100%'}
                    alt='logo'
                    loading='lazy'
                />
            </Box>
        );

        if (disabledLink) {
            return logo;
        }

        return (
            <Link href="/" sx={{ display: 'contents' }}>
                {logo}
            </Link>
        );
    }
);

export default Logo;

