import { useMemo } from "react";
import { STORAGE_INFOS } from "src/auth/context/auth-provider";
import { TableProps } from "src/components/table/props";
import { IUserItem, UserType } from "src/types/users";
import { endpoints, fetcher } from "src/utils/axios";
import useSWR from "swr";



const options = {
    keepPreviousData: true,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: false,
};

type ResponseReturn<T> = {
    data: T;
    loading: boolean;
    error: any;
    count?: number;
}


export function useGetAllCustomers(): ResponseReturn<UserType[]> {
    const URL = endpoints.dashboard.admin.customers.getAll;
    const { data, error, isLoading } = useSWR(URL, fetcher, options);

    const memoizedValue = useMemo(() => ({
        data: data?.customers || [] as UserType[],
        count: data?.totalCount || data?.customers?.length || 0,
        error,
        loading: isLoading,
    }), [data, error, isLoading]);

    return memoizedValue;
}

export function useGetCustomer(): {
    customer: IUserItem,
    Loading: boolean,
    mutate: () => void,
    error: any
} {
    const URL = `${endpoints.dashboard.customer.account.getMyInfos}`;

    const { data, isLoading, error, mutate } = useSWR(URL, fetcher, options);

    const memoizedValue = useMemo(
        () => ({
            customer: data || {} as IUserItem,
            Loading: isLoading,
            mutate,
            error
        }),
        [data, isLoading, error, mutate]
    );

    return memoizedValue;
}

export function useGetCustomerById(id?: string): {
    customer: IUserItem,
    Loading: boolean,
    mutate: () => void,
    error: any
} {
    const URL = id && `${endpoints.dashboard.admin.customers.getOne(id)}`
    const { data, isLoading, error, mutate } = useSWR(id ? [URL, id] : null, fetcher, options);
    const memoizedValue = useMemo(
        () => ({
            customer: data || {} as IUserItem,
            Loading: isLoading,
            mutate,
            error
        }),
        [data, isLoading, error, mutate]
    );
    return memoizedValue;
}
